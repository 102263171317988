<script>
import Multiselect from "vue-multiselect";
import required from "vuelidate/lib/validators";

export default {
  props: { 
      oldTipoMovimento: { type: Object, required: true }
    },
  components: { Multiselect },
  data() {
    return {
      loader: {get: false},
      titleBody: 'Alterar Tipo de Movimento',
      tipoMovimento: {},
      tiposReceita: [
        'Somar',
        'Diminuir',
        'Não gerar'
      ],
      tiposDespesa: [
        'Somar',
        'Diminuir',
        'Não gerar'
      ],
      movimentos: [
        'Entrada',
        'Saída',
        'Inventário',
        'Devolução'
      ],
      finalidades: [
          '1',
          '2',
          '3',
          '4',
      ],
    }
  },
  validations: {
    tipoMovimento: {
      nometpmov: { required },
      receitavenda: { required },
      despcompra: { required },
      tpmov: { required },
      tppreco: { required },
      finnfe: { required },
      es: { required },
      natop: { required }
    }
  },
  mounted() {
    this.setNewTitle();
    this.tipoMovimento = this.oldTipoMovimento;
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    async formSubmit(e) {
      this.submitted = true;
      // stop here if form is invalid
      await this.$v.$touch();

      this.validatesFields()
        .then(() => {
          this.setPut();
        });
    },
    setNewTitle() {
      this.$emit("newTitle", this.titleBody+' '+this.tipoMovimento.nometpmov);
    },
    setPut() {
      console.log('Gravou');
      this.$emit('doPut', this.tipoMovimento);
    },
  },
    
}
</script>

<template>
<div class="card">
  <div class="card-body">
    <i v-if="loader.get" class="fa fa-spinner fa-5x fa-spin text-success text-center"></i>
    <form id="form-tipo-movimento" role="form" class="form-horizontal" @submit.prevent="formSubmit" method="post">
      <b-card no-body class="mb-1">
        <b-card-body>
          <h4>Parâmetros para Movimentação Estoque/Financeiro/Fiscal</h4>
          <div class="form-row form-group" role="group">
            <div class="col-sm-12 col-md-3">
                <div class="form-group">
                  <label for="tipo_movimento_id" class="col-form-label">Código</label>
                  <div class="col">
                    <input disabled v-model="tipoMovimento.id" class="form-control text-right" type="text" placeholder="Gerado pelo sistema" id="tipo_movimento_id">
                  </div>
                </div>
              </div>
          </div>
          <div class="form-row form-group" role="group">
            <div class="col-sm-12 col-md-8">
                <div class="form-group">
                  <label for="tipo_movimento_nometpmov" class="col-form-label">Nome do Tipo Movimento</label>
                  <div class="col">
                    <input v-model="tipoMovimento.nometpmov" class="form-control" type="text" placeholder="Venda, devolução, Compra..." id="tipo_movimento_id">
                  </div>
                </div>
              </div>
          </div>
          <div class="form-row form-group" role="group">
            <div class="col-sm-12 col-md-3">
              <div class="form-group">
                <label for="tipo_movimento_receitavenda" class="col-form-label">Tipo de receita</label>
                <div class="col">
                  <Multiselect
                   id="tipo_movimento_receitavenda"
                   v-model="tipoMovimento.receitavenda"
                   :options="tiposReceita"
                   placeholder="Selecione"
                  ></Multiselect>
                </div>
              </div>
            </div>
            <div class="col-sm-12 col-md-3">
              <div class="form-group">
                <label for="tipo_movimento_despcompra" class="col-form-label">Tipo Despesa</label>
                <div class="col">
                  <Multiselect
                   id="tipo_movimento_despcompra"
                   v-model="tipoMovimento.despcompra"
                   :options="tiposDespesa"
                   placeholder="Selecione"
                  ></Multiselect>
                </div>
              </div>
            </div>
            <div class="col-sm-12 col-md-3">
              <div class="form-group">
                <label for="tipo_movimento_tpmov" class="col-form-label">Tipo Movimento</label>
                <div class="col">
                  <Multiselect
                   id="tipo_movimento_tpmov"
                   v-model="tipoMovimento.tpmov" 
                   :options="movimentos" 
                   placeholder="Selecione"
                  ></Multiselect>
                </div>
              </div>
            </div>
          </div>
          <div class="form-row form-group" role="group">
            <div class="col-sm-12 col-md-4">
              <div class="form-group">
                <div class="col">
                  <b-form-checkbox
                   id="tipo-movimento-infoparcpg"
                   v-model="tipoMovimento.infoparcpg"
                   name="infoparcpg"
                   value="1"
                   unchecked-value="0"
                  >
                    Gerar Parcelas de Pagamentos
                  </b-form-checkbox>
                </div>
                <div class="col">
                  <b-form-checkbox
                   id="tipo-movimento-movestoque"
                   v-model="tipoMovimento.movestoque"
                   name="movestoque"
                   value="1"
                   unchecked-value="0"
                  >
                    Movimentar Estoque
                  </b-form-checkbox>
                </div>
              </div>
            </div>
            <div class="col-sm-12 col-md-4">
              <h5 class="font-size-14 mb-4">Preço padrão</h5>
              <div class="form-check">
                <input v-model="tipoMovimento.tppreco" type="radio" name="tppreco" id="preco-preco" value="preco" checked="checked" class="form-check-input">
                <label for="preco-preco" class="form-check-label">Preço Balcão</label>
              </div>
              <div class="form-check">
                <input v-model="tipoMovimento.tppreco" type="radio" name="tppreco" id="preco-preco-atacado" value="precoatacado" class="form-check-input">
                <label for="preco-preco-atacado" class="form-check-label">Preço Atacado</label>
              </div>
            </div>
            <div class="col-sm-12 col-md-4">
              <h5 class="font-size-14 mb-4">Tipo Documento</h5>
              <div class="form-check">
                <input v-model="tipoMovimento.es" type="radio" name="es" id="es-0" value="0" checked="checked" class="form-check-input">
                <label for="es-0" class="form-check-label">Entrada</label>
              </div>
              <div class="form-check">
                <input v-model="tipoMovimento.es" type="radio" name="es" id="es-1" value="1" class="form-check-input">
                <label for="es-1" class="form-check-label">Saída</label>
              </div>
            </div>
          </div>          
          <div class="form-row form-group" role="group">
            <div class="col-sm-12 col-md-8">
                <div class="form-group">
                  <label for="tipo_movimento_natop" class="col-form-label">Natureza da Operação</label>
                  <div class="col">
                    <input v-model="tipoMovimento.natop" class="form-control" type="text" placeholder="Venda, devolução, Compra..." id="tipo_movimento_natop">
                  </div>
                </div>
              </div>
          </div>
          <div class="form-row form-group" role="group">
            <div class="col-sm-12 col-md-4">
              <div class="form-group">
                <label for="tipo_movimento_finnfe" class="col-form-label">Finalidade</label>
                <div class="col">
                  <Multiselect id="tipo_movimento_finnfe" v-model="tipoMovimento.finnfe" :options="finalidades" placeholder="Selecione"></Multiselect>
                </div>
              </div>
            </div>
            <div class="col-sm-12 col-md-2">
              <div class="form-group">
                <label for="tipo-movimento-cfop" class="col-form-label">C. F. O. P.</label>
                <div class="col">
                  <input v-model="tipoMovimento.cfop" class="form-control text-right" type="text" placeholder="Código Fiscal Operação Produto" id="tipo_movimento_cfop">
                </div>
              </div>
            </div>
            <div class="col-sm-12 col-md-2">
              <div class="form-group">
                <label for="tipo-movimento-serie" class="col-form-label">Série</label>
                <div class="col">
                  <input v-model="tipoMovimento.serie" class="form-control text-right" type="text" placeholder="Série do documento" id="tipo_movimento_serie">
                </div>
              </div>
            </div>
          </div>
        </b-card-body>
      </b-card>
    </form>
  </div>
  <div class="card-body">
    <div class="col-md-12 mt-10">
      <button class="btn btn-light"
       @click="setPut">Gravar</button>
    </div>
  </div>
</div>
</template>