<script>
import Multiselect from "vue-multiselect";
import required from "vuelidate/lib/validators";

export default {
  components: { Multiselect },
  data() {
    return {
      loader: {get: false},
      titleBody: 'Incluir Tipo de Movimento',
      tipoMovimento: {},
      tipoReceita: [
        'Somar',
        'Diminuir',
        'Não gerar'
      ],
      tipoDespesa: [
        'Somar',
        'Diminuir',
        'Não gerar'
      ],
      movimento: [
        'Entrada',
        'Saída',
        'Inventário',
        'Devolução'
      ],
      finalidade: [
          '1',
          '2',
          '3',
          '4',
      ],
    }
  },
  validations: {
    tipoMovimento: {
      nometpmov: { required },
      receitavenda: { required },
      despcompra: { required },
      tpmov: { required },
      tppreco: { required },
      finnfe: { required },
      es: { required },
      natop: { required }
    }
  },
  mounted() {
      this.setNewTitle();
  },
  methods: {
    setNewTitle() {
      this.$emit("newTitle", this.titleBody);
    },
    setPost() {
      this.$emit('doPost', this.tipoMovimento);
    },
  },
    
}
</script>

<template>
<div class="card">
  <div class="card-body">
    <i v-if="loader.get" class="fa fa-spinner fa-5x fa-spin text-success text-center"></i>
    <form id="form-tipo-movimento" role="form" class="form-horizontal">
      <b-card no-body class="mb-1">
        <b-card-body>
          <h4>Parâmetros para Movimentação Estoque/Financeiro/Fiscal</h4>
          <div class="form-row form-group" role="group">
            <div class="col-sm-12 col-md-3">
                <div class="form-group">
                  <label for="tipo_movimento_id" class="col-form-label">Código</label>
                  <div class="col">
                    <input disabled v-model="tipoMovimento.id" class="form-control text-right" type="text" placeholder="Gerado pelo sistema" id="tipo_movimento_id">
                  </div>
                </div>
              </div>
          </div>
          <div class="form-row form-group" role="group">
            <div class="col-sm-12 col-md-8">
                <div class="form-group">
                  <label for="tipo_movimento_nometpmov" class="col-form-label">Nome do Tipo Movimento</label>
                  <div class="col">
                    <input v-model="tipoMovimento.nometpmov" class="form-control" type="text" placeholder="Venda, devolução, Compra..." id="tipo_movimento_id">
                  </div>
                </div>
              </div>
          </div>
          <div class="form-row form-group" role="group">
            <div class="col-sm-12 col-md-3">
              <div class="form-group">
                <label for="tipo_movimento_receitavenda" class="col-form-label">Tipo de receita</label>
                <div class="col">
                  <multiselect v-model="tipoMovimento.receitavenda" :options="tipoReceita" placeholder="Selecione">
                    <template></template>
                  </multiselect>
                </div>
              </div>
            </div>
            <div class="col-sm-12 col-md-3">
              <div class="form-group">
                <label for="tipo_movimento_despcompra" class="col-form-label">Tipo Despesa</label>
                <div class="col">
                  <multiselect v-model="tipoMovimento.despcompra" :options="tipoDespesa" placeholder="Selecione">
                    <template></template>
                  </multiselect>
                </div>
              </div>
            </div>
            <div class="col-sm-12 col-md-3">
              <div class="form-group">
                <label for="tipo_movimento_tpmov" class="col-form-label">Tipo Movimento</label>
                <div class="col">
                  <multiselect v-model="tipoMovimento.tpmov" :options="movimento" placeholder="Selecione">
                    <template></template>
                  </multiselect>
                </div>
              </div>
            </div>
          </div>
          <div class="form-row form-group" role="group">
            <div class="col-sm-12 col-md-4">
              <div class="form-group">
                <div class="col">
                    <b-form-checkbox
                     id="tipo-movimento-infoparcpg"
                     v-model="tipoMovimento.infoparcpg"
                     name="infoparcpg"
                     value="1"
                     unchecked-value="0"
                    >
                        Gerar Parcelas de Pagamentos
                    </b-form-checkbox>
                </div>
                <div class="col">
                    <b-form-checkbox
                     id="tipo-movimento-movestoque"
                     v-model="tipoMovimento.movestoque"
                     name="movestoque"
                     value="1"
                     unchecked-value="0"
                    >
                        Movimentar Estoque
                    </b-form-checkbox>
                </div>
              </div>
            </div>
            <div class="col-sm-12 col-md-4">
              <h5 class="font-size-14 mb-4">Preço padrão</h5>
              <div class="form-check">
                <input v-model="tipoMovimento.tppreco" type="radio" name="classe" id="preco-preco" value="preco" checked="checked" class="form-check-input">
                <label for="preco-preco" class="form-check-label">Preço Balcão</label>
              </div>
              <div class="form-check">
                <input v-model="tipoMovimento.tppreco" type="radio" name="classe" id="preco-preco-atacado" value="precoatacado" class="form-check-input">
                <label for="preco-preco-atacado" class="form-check-label">Preço Atacado</label>
              </div>
            </div>
            <div class="col-sm-12 col-md-4">
              <h5 class="font-size-14 mb-4">Tipo Documento</h5>
              <div class="form-check">
                <input v-model="tipoMovimento.es" type="radio" name="classe" id="es-0" value="0" checked="checked" class="form-check-input">
                <label for="es-0" class="form-check-label">Entrada</label>
              </div>
              <div class="form-check">
                <input v-model="tipoMovimento.es" type="radio" name="classe" id="es-1" value="1" class="form-check-input">
                <label for="es-1" class="form-check-label">Saída</label>
              </div>
            </div>
          </div>          
          <div class="form-row form-group" role="group">
            <div class="col-sm-12 col-md-8">
                <div class="form-group">
                  <label for="tipo_movimento_natop" class="col-form-label">Natureza da Operação</label>
                  <div class="col">
                    <input v-model="tipoMovimento.natop" class="form-control" type="text" placeholder="Venda, devolução, Compra..." id="tipo_movimento_natop">
                  </div>
                </div>
              </div>
          </div>
          <div class="form-row form-group" role="group">
            <div class="col-sm-12 col-md-4">
              <div class="form-group">
                <label for="tipo-movimento-cfop" class="col-form-label">Finalidade</label>
                <div class="col">
                    <Multiselect 
                    id="tipo-movimento-finnfe"
                    v-model="tipoMovimento.finnfe"
                    :options="finalidade"
                    placeholder="Selecione"
                    >
                    </Multiselect>
                </div>
              </div>
            </div>
            <div class="col-sm-12 col-md-2">
              <div class="form-group">
                <label for="tipo-movimento-cfop" class="col-form-label">C. F. O. P.</label>
                <div class="col">
                  <input v-model="tipoMovimento.cfop" class="form-control text-right" type="text" placeholder="Código Fiscal Operação Produto" id="tipo_movimento_cfop">
                </div>
              </div>
            </div>
            <div class="col-sm-12 col-md-2">
              <div class="form-group">
                <label for="tipo-movimento-serie" class="col-form-label">Série</label>
                <div class="col">
                  <input v-model="tipoMovimento.serie" class="form-control text-right" type="text" placeholder="Série do documento" id="tipo_movimento_serie">
                </div>
              </div>
            </div>
          </div>
        </b-card-body>
      </b-card>
    </form>
  </div>
  <div class="card-body">
    <div class="col-md-12 mt-10">
      <button class="btn btn-light"
       @click="setPost">Gravar</button>
    </div>
  </div>
</div>
</template>