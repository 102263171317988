<script>
export default {
  props: {
    tipoMovimento: { type: Object }
  },
}
</script>

<template>
<div id="tipoMovimento">
  <p>Nome: {{ tipoMovimento.nometpmov }}</p>
  <p>Receitas Venda: {{ tipoMovimento.receitavenda}} </p>
  <p>Despesas Compra: {{ tipoMovimento.despcompra}} </p>
  <p>Tipo do Movimento: {{ tipoMovimento.tpmov}} </p>
  <p>Informa Parcelas: <b-badge data-name="infoparcpg" class="field-infoparcpg" v-bind:variant="tipoMovimento.infoparcpg ? 'success' : 'danger'">{{ (tipoMovimento.infoparcpg) ? 'Sim' : 'Não'}}</b-badge></p>
  <p>Movimento Estoque: <b-badge data-name="movestoque" class="field-movestoque" v-bind:variant="tipoMovimento.movestoque ? 'success' : 'danger'">{{ (tipoMovimento.movestoque) ? 'Sim' : 'Não'}}</b-badge></p>
  <p>Preço Padrão: <b-badge data-name="tppreco" class="field-tppreco" variant="light">{{ tipoMovimento.tppreco}}</b-badge></p>
  <p>Finalidade DF-e: {{ tipoMovimento.finnfe}} </p>
  <p>C. F. O. P.: {{ tipoMovimento.cfop}} </p>
  <p>Entrada/Saída: {{ tipoMovimento.es}} </p>
  <p>Natureza da Operação: {{ tipoMovimento.natop}} </p>
  <p>Série: {{ tipoMovimento.serie}} </p>
  <p>Criado: <b-badge data-name="created_at" class="field-created_at" variant="secondary">{{ centroCusto.created_at }}</b-badge></p>
  <p>Última alteração: <b-badge data-name="updated_at" class="field-updated_at" variant="secondary">{{ centroCusto.updated_at }}</b-badge></p>
</div>
</template>